<template>
    <div class="index">
        <!-- <Home v-if="value == '首页'"></Home>
        <About v-if="value == '报表'"></About> -->
        <template v-for="(item,index) in routerList">
            <component :is="item.component" v-if="item.name==value" :key="index"
            ></component>
        </template>
        <div class="tabs" id="tabsBox">
            <v-bottom-navigation v-model="bottomNav" :fixed="true" color="#4D78AE" :input-value="change" style="height:auto;">
                <v-btn v-for="(item,index) in routerList" :key="index" :value=index @click="changeBtn(item.name)" height="56" width="50%">
                <span>{{item.name}}</span>
                <img class="tabsimg" :src="bottomNav!== index? item.Icon : item.activeIcon" alt="">
                </v-btn>
            </v-bottom-navigation>
        </div>
    </div>
</template>

<script>
// import Home from './Home';
// import About from './About';
import routerObj from '../router/index'
import md5 from 'js-md5'

export default {
  name: 'index',
  components: {
    // Home,
    // About
  },
  data () {
    return {
      routerList:[],
      bottomNav: 0,
      change:true,
      value:"首页",
    }
  },
  methods: {
    tabChangeEvent() {
      // if(!$core.getLocal('HomeId')) {
        let now = new Date();
        let nowTime = now.getTime();//获取当前时间时间戳
        $core.setLocal('HomeId',this.CalcuMD5(String(nowTime)))
        if($core.getLocal('value') == '报表') {
          this.getUserActive('进入报表','进入报表')
        }else {
          if($core.getUrlKey("isArticle")) {
            this.getUserActive('进入主页','消息进入主页')
          }else {
            this.getUserActive('进入主页','应用进入主页')
          }
        }
      // }  
    },
    changeBtn(name) {//tan点击事件
    //   console.log(name)
      this.value = name
      $core.setLocal("value",name);
      if(name == '首页') {
        this.tabChangeEvent()
      }
      if(name == '报表') {
        this.tabChangeEvent()
      }
    },
    CalcuMD5(pwd) {
      let num = String(Math.floor(Math.random() * (10000 - 1)) + 1)
      pwd = md5(num+pwd);
      return pwd;
    },
    getUserActive(name,event) {
      $core.request('growdata/add', res => {
        // console.log(res)
      },{
        'id':'',//消息id
        "page_id":$core.getLocal('HomeId'),//页面id
        'name':encodeURI(name),//事件类型名称
        'event':encodeURI(event),//事件
      });
    },
  },
  destroyed() {
    if($core.getLocal('value') == '首页') {
      $core.getLocal("homeTime");
    }
    if($core.getLocal('value') == '报表') {
      $core.getLogcal("statement");
    }

  },
  created() {
    $core.clearLocal('StatementOneId')
    $core.clearLocal('StatementOneMsgId')
    $core.clearLocal('StatementTwoId')
    $core.clearLocal('StatementTwoMsgId')
    this.routerList=routerObj.options.indexArr
    console.log('routerList', this.routerList)
    // console.log($core.getLocal("value"))
    this.value = $core.getLocal("value")?$core.getLocal("value"):'首页'
    if($core.getLocal("value") == '报表') {
      this.bottomNav = 1
    }
    if($core.getLocal('value')) {
      if($core.getLocal('value') == '报表') {
        this.tabChangeEvent()
      }
    }
  }
};
</script>

<style lang="less" scoped>
  .index {
    .tabs {
      // position: fixed;
      // bottom: 0;
      width: 100%;
    }
    /deep/ .v-item-group.v-bottom-navigation .v-btn {
      max-width: 50%;
    }
    /deep/ .v-item-group.v-bottom-navigation {
        align-items: center;
        width: 100%;
        box-shadow: 0px;
        img {
          width: 22px;
          height: 22px;
          margin-bottom: 3px;
        }
    }
    .tabsimg {
      width: 22px;
      height: 22px;
    }
  }
</style>